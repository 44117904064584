<template>
  <AppForm
    :id="id"
    ref="actionForm"
    class="p-1"
    :title="
      action
        ? $t('edit_form', { name: action.name }) || `Edit ${action.name}`
        : $t('add_new_action') || 'Add New Action'
    "
    :errors-bag="errorsBag"
    :success-message="successMessage"
    :is-loading="isLoading"
    @hide="hideForm"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="actionData.name"
      type="text"
      required
      :label="$t('action') || 'Action'"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm } from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    action: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      actionData: {
        name: null,
      },
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.actions.isLoading.creating ||
        this.$store.state.actions.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.actions.errors;
    },
  },
  watch: {
    action: {
      handler(val) {
        if (!val) {
          this.actionData = {
            name: null,
          };
          return;
        }
        this.actionData = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      const actionName = this.action ? "updateData" : "createData";

      this.$store
        .dispatch(`actions/${actionName}`, this.actionData)
        .then(() => {
          this.hideForm();
          this.successMessage = this.$t("action_was_successfully_saved");
        });
    },
    hideForm() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.actionData = {
        name: null,
      };
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
