<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <AppButton
        class="d-block m-2 ml-auto"
        variant="primary"
        @click="openActionForm()"
      >
        <span class="text-nowrap">
          {{ $t("add_new_action") || "Add New Action" }}
        </span>
      </AppButton>
      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="actions"
          hover
          striped
          responsive
          primary-key="id"
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(name)="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="data.item.name"
                :icon="data.item.name"
                size="18"
                class="mr-50"
                :class="`text-${data.item.name}`"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.name }}
              </span>
            </div>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                variant="primary"
                class="mb-1"
                @click="openActionForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div v-if="actions && actions.length" class="m-2">
          <span class="text-muted" :data-test="actions.length">
            {{ $t("total_entries", { total: actions.length }) }}
          </span>
        </div>
      </b-overlay>
    </b-card>
    <ActionForm
      :id="'action-form'"
      :action="activeAction"
      @hide="hideActionForm"
    />
    <deleteWarning
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deleteAction"
    />
  </div>
</template>

<script>
import { BCard, BTable, BOverlay } from "bootstrap-vue";

import ActionForm from "./partials/ActionForm.vue";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import { AppButton } from "@/components/form/index";

export default {
  name: "Actions",
  components: {
    ActionForm,
    BCard,
    AppButton,
    BTable,
    BOverlay,
    deleteWarning,
  },
  data() {
    return {
      activeAction: null,
      fields: [
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "id",
          label: this.$t("id") || "ID",
        },
        {
          key: "name",
          label: this.$t("name") || "Name",
        },
      ],
      isDeletionWarnShown: false,
    };
  },
  computed: {
    actions() {
      return this.$store.state.actions.data;
    },
    isLoading() {
      return this.$store.state.actions.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.actions.isLoading.deleting;
    },
  },
  created() {
    this.fetchActions();
  },
  methods: {
    openActionForm(item = null) {
      if (item) this.activeAction = item;
      this.$root.$emit("bv::show::modal", "action-form");
    },
    hideActionForm() {
      this.activeAction = null;
    },
    fetchActions() {
      this.$store.dispatch("actions/fetchData");
    },
    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activeAction = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activeAction = null;
    },
    deleteAction() {
      this.$store.dispatch("actions/deleteData", this.activeAction).then(() => {
        this.activeAction = null;
        this.isDeletionWarnShown = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
